import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
const Success = () => {
  return (
    <div>
      <Navbar />
      <section class="bg-white dark:bg-gray-900">
        <div class="container px-6 py-10 mx-auto">
          <h1 class="text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl dark:text-white">
            Successful Payment
          </h1>

          <div class="flex justify-center mx-auto mt-6">
            <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
            <span class="inline-block w-3 h-1 mx-1 bg-blue-500 rounded-full"></span>
            <span class="inline-block w-1 h-1 bg-blue-500 rounded-full"></span>
          </div>

          <div class="flex items-start max-w-6xl mx-auto mt-16">
            <div>
              <p class="flex items-center text-center text-gray-500 lg:mx-8">
                Thank you for your payment. You will receive an email with your
                invoice shortly. You will receive a private access code to 
                our app before January.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="mt-[50px] lg:fixed lg:bottom-0 lg:w-full">
        <Footer />
      </div>
    </div>
  );
};

export default Success;
