import Navbar from "../components/Navbar.js";
import jc from "../images/jc.jpg";
import wallst from "../images/wallst.jpg";
import selectusa from "../images/selectusa.jpg";
import price from "../images/price.jpg";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Card = () => {
  // create a state variable for the carousel index
  const [index, setIndex] = useState(0);

  const next = (e) => {
    //prevent event default
    e.preventDefault();
    setIndex(index === images.length - 1 ? 0 : index + 1);
    console.log("clicked", index);
  };

  const previous = (e) => {
    //prevent event default
    e.preventDefault();
    setIndex(index === 0 ? images.length - 1 : index - 1);
    console.log("clicked", index);
  };

  const images = [jc, selectusa, wallst, price];
  const text = [
    "JC Le-Feuvre is a stock market veteran, who has been trading stocks for over 20 years. JC has been a CEO of several companies, and a mentor to many entrepreneurs. His experience in charge of multi-million dollar companies has allowed him to develop a unique perspective on the stock market, understanding the deep connection between the CEO and performance of his company's stock in the market.",
    "Jean Christophe Le Feuvre's experience has allowed him as the CEO of Piana HR Group, an established leader in recruitment process outsourcing, has allowed him to understand the deep connection between the company's success and its human capital management. Often people make the mistake of looking at tangible physical features of a stock, their machinery, production, but rarely look at how their human capital affects the future success of a company. Jean Christophe's unique experience has allowed him to develop an unique investing strategy and viewpoint that has been recognized in gigantic investing summits such as the 2017 Select USA investment summit.",
    "In 2022, amidst the current economical landscape, and the unprecedented state of the world during the pandemic, the markets have been now harder than ever to time. Which has prompted many investors of all kinds to make brash emotional decisions on their stock investments, Jean Christophe Le Feuvre has looked at this as an opportunity to help people in need of financial literacy. Piana Investing Club was born as a way to educate and help people who want to create  and grow their wealth. Most people today think saving money is the only way to generate wealth, but inflation has disproven this type of thinking. Wealth is created by investing time in your financial education, and Piana Investing Club has come to exist to help you cultivate your financial knowledge and your wealth.",
    "Our goals as a club is to unite investors from all walks of life during uncertain times like these where the connection with different types of investors is key, right now is more important than ever to pay attention to putting your own money to work and potentially build wealth, smart investing allows your money to outpace rampant inflation and increase in value.                Making money doesn't have to be a lonely sport. Join the ranks of like-minded people in our club, investing starts with getting invested.",
  ];
  const theh1s = ["Learn more about what"];
  const headers = [
    "CEO, entrepreneur and seasoned investor",
    "Understanding people is key to our vision",
    "How we started",
    "Join us",
  ];
  return (
    <section class="bg-white dark:bg-gray-900">
      <div class="relative flex">
        <div class="min-h-screen lg:w-1/3"></div>
        <div class="hidden w-3/4 min-h-screen bg-gray-100 dark:bg-gray-800 lg:block"></div>

        <div class="container flex flex-col justify-center w-full min-h-screen px-6 py-10 mx-auto lg:absolute lg:inset-x-0">
          <h1 class="text-3xl font-semibold text-gray-800 lg:text-4xl dark:text-white">
            Meet <span class="text-blue-500">the director of our club,</span>{" "}
            <br></br> Jean Christophe Le-Feuvre
          </h1>

          {index === 0 ? (
            <div class="mt-10 lg:mt-20 lg:flex lg:items-center">
              <img
                class="object-cover object-center w-full lg:w-[32rem] rounded-lg h-96"
                src={images[index]}
                alt=""
              />

              <div class="mt-8 lg:px-10 lg:mt-0">
                <h1 class="text-2xl font-semibold text-gray-800 dark:text-white lg:w-72">
                  {headers[index]}
                </h1>

                <p class="max-w-lg mt-6 text-gray-500 dark:text-gray-400">
                  {text[index]}
                </p>
                <div class="aspect-w-16 aspect-h-9">
                  <p class="max-w-lg mt-6 text-gray-500 dark:text-gray-400 font-bold">
                    Jean Christophe on a business interview in France's most
                    respected business and news channel.
                  </p>
                  <iframe
                    src="https://www.youtube.com/embed/t0158WnNN_4"
                    className="mt-[80px]"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <p class="max-w-lg mt-[120px] dark:text-gray-400 text-gray-500 font-bold">
                  Jean Christophe's latest book on how to revolutionize France's
                  human resources industry.
                </p>
                <a href="https://www.amazon.com/travail-mesures-simples-efficaces-lemploi/dp/2212553455/ref=sr_1_1?crid=FQ0YZBIW9YGA&keywords=jean+christophe+le+feuvre&qid=1670383539&sprefix=jean+christophe+le+feuv%2Caps%2C190&sr=8-1">
                  <img
                    class="w-[200px]"
                    src="https://m.media-amazon.com/images/I/41BMP4dPSjL._SX318_BO1,204,203,200_.jpg"
                  ></img>
                </a>
                <h3 class="mt-6 text-lg font-medium text-blue-500">
                  Click the right arrow
                </h3>
                <p class="text-gray-600 dark:text-gray-300">
                  to learn more about our vision
                </p>
              </div>
            </div>
          ) : (
            <div>
              <div class="mt-10 lg:mt-20 lg:flex lg:items-center">
                <img
                  class="object-cover object-center w-full lg:w-[32rem] rounded-lg h-96"
                  src={images[index]}
                  alt=""
                />

                <div class="mt-8 lg:px-10 lg:mt-0">
                  <h1 class="text-2xl font-semibold text-gray-800 dark:text-white lg:w-72">
                    {headers[index]}
                  </h1>

                  <p class="max-w-lg mt-6 text-gray-500 dark:text-gray-400">
                    {text[index]}
                  </p>
                  <h3 class="mt-6 text-lg font-medium text-blue-500">
                    Click the right arrow
                  </h3>
                  <p class="text-gray-600 dark:text-gray-300">
                    to learn more about our vision
                  </p>
                </div>
              </div>
            </div>
          )}

          <div class="flex items-center justify-between mt-12 lg:justify-start">
            <button
              onClick={previous}
              title="left arrow"
              class="p-2 text-gray-800 transition-colors duration-300 border rounded-full rtl:-scale-x-100 dark:border-gray-700 dark:text-gray-200 dark:hover:bg-gray-800 hover:bg-gray-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>

            <button
              title="right arrow"
              onClick={next}
              class="p-2 text-gray-800 transition-colors duration-300 border rounded-full rtl:-scale-x-100 dark:border-gray-700 dark:text-gray-200 dark:hover:bg-gray-800 lg:mx-6 hover:bg-gray-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
            <Link to="/packages">
              <button className="px-8 py-8 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-900 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                JOIN US
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

const About = () => {
  return (
    <div>
      <Navbar />
      <Card />
    </div>
  );
};

export default About;
