import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
const Disclaimer = () => {
  return (
    <div>
      <Navbar />
      <section class="bg-white dark:bg-gray-900">
        <div class="container px-6 py-10 mx-auto">
          <h1 class="text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl dark:text-white">
            Piana Investing Club Legal disclaimer
          </h1>

          <div class="flex justify-center mx-auto mt-6">
            <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
            <span class="inline-block w-3 h-1 mx-1 bg-blue-500 rounded-full"></span>
            <span class="inline-block w-1 h-1 bg-blue-500 rounded-full"></span>
          </div>

          <div class="flex items-start max-w-6xl mx-auto mt-16">
            <div>
              <p class="flex items-center text-center text-gray-500 lg:mx-8">
                Any financial opinions offered in connection with Piana
                Investing Club are based upon information considered to be
                reliable, but neither Piana Investing Club (including Piana
                LLC), nor any analyst (including Jean Christophe Le Feuvre)
                warrant the completeness or accuracy of, or are under any
                obligation to update or correct, any information provided to you
                in connection with the Piana Investing Club. The opinions
                offered in connection with Piana Investing Club Content are
                subject to change without notice. The opinions made known to you
                in connection with Piana Investing Club are not exclusive to you
                and may have been previously, or subsequently, discussed by Jean
                Christophe Le-Feuvre and other featured members of the club on
                television, radio, internet, or other mediums.<br></br>
                Past performance is not indicative of future results. No
                specific outcome or profit is guaranteed in connection with your
                reliance upon or other use of the Piana Investing Club. The
                opinions offered in connection with the Piana Investing Club are
                not an attempt to induce any particular trading behavior,
                investment or strategy. You should be aware of the risk of loss
                in following any strategy or investment discussed in the Piana
                Investing Club. Investments or strategies made known to you in
                connection with the Piana Investing Club: (a) may not be
                suitable for you; (b) do not take into account your particular
                investment objectives, financial situation or needs; and (c) are
                not intended to provide investment advice or recommendations
                appropriate for you. Before making any investment or trade,
                consider whether it is suitable for you and consider seeking
                advice from your own financial or investment adviser.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="mt-[50px] lg:fixed lg:bottom-0 lg:w-full">
        <Footer />
      </div>
    </div>
  );
};

export default Disclaimer;
