import Navbar from "../components/Navbar";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomerContext } from "../CustomerContext";
import storageService from "../storageService.js";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Packages = () => {
  const [initialDeposit, setInitialDeposit] = useState(undefined);
  const [checkboxState, setCheckboxState] = useState(false);
  const navigate = useNavigate();
  const { loggedCustomer, setLoggedCustomer } = useContext(CustomerContext);
  useEffect(() => {
    const loggedUserJSON = window.localStorage.getItem("loggedCustomerJWT");
    if (loggedUserJSON) {
      const user = JSON.parse(loggedUserJSON);
      setLoggedCustomer(user);
      storageService.setToken(user.token);
      console.log("variable set");
    }
    console.log("variable not set");
  }, []);

  const changeCheckbox = () => {
    setCheckboxState(!checkboxState);
  };

  const handleClickStarter = async (event) => {
    event.preventDefault();

    // if checkbox is checked, go inside if statement, if not, navigate to /disclaimer

    if (checkboxState === true) {
      if (loggedCustomer) {
        try {
          const packageName = "pianastarter";
          const customer = await axios.post("/api/checkout", {
            product: packageName,
            customerID: loggedCustomer.data.billingID,
            email: loggedCustomer.data.email,
          });
          console.log(customer);
          window.location.replace(customer.data);
        } catch (error) {
          console.log(error.message);
        }
      } else {
        navigate("/login");
      }
    } else {
      navigate("/disclaimer");
    }
  };

  const handleClickPremium = async (event) => {
    event.preventDefault();
    if (checkboxState === true) {
      if (loggedCustomer) {
        try {
          const packageName = "pianapremium";
          const customer = await axios.post("/api/checkout", {
            product: packageName,
            customerID: loggedCustomer.data.billingID,
            email: loggedCustomer.data.email,
          });
          console.log(customer);
          window.location.replace(customer.data);
        } catch (error) {
          console.log(error.message);
        }
      } else {
        navigate("/login");
      }
    } else {
      navigate("/disclaimer");
    }
  };

  const handleClickProTrader = async (event) => {
    event.preventDefault();
    if (checkboxState === true) {
      if (loggedCustomer) {
        try {
          const packageName = "pianaprotrader";
          const customer = await axios.post("/api/checkout", {
            product: packageName,
            customerID: loggedCustomer.data.billingID,
            email: loggedCustomer.data.email,
          });
          console.log(customer);
          window.location.replace(customer.data);
        } catch (error) {
          console.log(error.message);
        }
      } else {
        navigate("/login");
      }
    } else {
      navigate("/disclaimer");
    }
  };

  const handleClickStarterYearly = async (event) => {
    event.preventDefault();
    if (checkboxState === true) {
      if (loggedCustomer) {
        try {
          const packageName = "pianastarteryearly";
          const customer = await axios.post("/api/checkout", {
            product: packageName,
            customerID: loggedCustomer.data.billingID,
            email: loggedCustomer.data.email,
          });
          console.log(customer);
          window.location.replace(customer.data);
        } catch (error) {
          console.log(error.message);
        }
      } else {
        navigate("/login");
      }
    } else {
      navigate("/disclaimer");
    }
  };

  const handleClickPremiumYearly = async (event) => {
    event.preventDefault();
    if (checkboxState === true) {
      if (loggedCustomer) {
        try {
          const packageName = "pianapremiumyearly";
          const customer = await axios.post("/api/checkout", {
            product: packageName,
            customerID: loggedCustomer.data.billingID,
            email: loggedCustomer.data.email,
          });
          console.log(customer);
          window.location.replace(customer.data);
        } catch (error) {
          console.log(error.message);
        }
      } else {
        navigate("/login");
      }
    } else {
      navigate("/disclaimer");
    }
  };

  const handleClickProTraderYearly = async (event) => {
    event.preventDefault();
    if (checkboxState === true) {
      if (loggedCustomer) {
        try {
          const packageName = "pianaprotraderyearly";
          const customer = await axios.post("/api/checkout", {
            product: packageName,
            customerID: loggedCustomer.data.billingID,
            email: loggedCustomer.data.email,
          });
          console.log(customer);
          window.location.replace(customer.data);
        } catch (error) {
          console.log(error.message);
        }
      } else {
        navigate("/login");
      }
    } else {
      navigate("/disclaimer");
    }
  };

  return (
    <div className="overflow-x-hidden">
      <Navbar />
      <div class="bg-gray-900">
        <div class="container px-6 py-8 mx-auto">
          <div class="xl:items-center xl:-mx-8 xl:flex">
            <div class="flex flex-col items-center xl:items-start xl:mx-8">
              <h1 class="text-3xl font-medium  capitalize lg:text-4xl text-white">
                Our Pricing Plan
              </h1>

              <div class="mt-4">
                <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
                <span class="inline-block w-3 h-1 mx-1 bg-blue-500 rounded-full"></span>
                <span class="inline-block w-1 h-1 bg-blue-500 rounded-full"></span>
              </div>

              <p class="mt-4 font-medium  text-gray-300">
                You can get All Access by selecting your plan!
              </p>

              <Link to="/about">
                <a
                  href="#"
                  class="flex items-center mt-4 -mx-1 text-sm  capitalize text-blue-400 hover:underline  hover:text-blue-500"
                >
                  <span class="mx-1">read more</span>
                  <svg
                    class="w-4 h-4 mx-1 rtl:-scale-x-100"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </a>
              </Link>
            </div>

            <div class="flex-1 xl:mx-8">
              <div class="mt-8 space-y-8 md:-mx-4 md:flex md:items-center md:justify-center md:space-y-0 xl:mt-0">
                <div class="max-w-sm mx-auto border rounded-lg md:mx-4 border-gray-700">
                  <div class="p-6">
                    <h1 class="text-xl font-medium  capitalize lg:text-3xl text-white">
                      Piana Starter
                    </h1>

                    <p class="mt-4 text-gray-300">
                      Get access to our club and our services, including our
                      exclusive portfolio, watchlist and mobile app.
                    </p>

                    <h2 class="mt-4 text-2xl font-medium  sm:text-4xl text-gray-300">
                      $99.99 <span class="text-base font-medium">/month</span>
                    </h2>

                    <p class="mt-1  text-gray-300">
                      Or $599.99 per year{" "}
                      <span className="text-blue-400">
                        (save $599.89 yearly)
                      </span>
                    </p>
                    {/* {loggedCustomer.data.plan === "none" || !loggedCustomer ? ( */}
                    <div>
                      <button
                        onClick={handleClickStarterYearly}
                        class="w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                      >
                        Pay Yearly
                      </button>
                      <button
                        onClick={handleClickStarter}
                        class="w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                      >
                        Pay Monthly
                      </button>

                      <fieldset>
                        <legend class="sr-only">Checkbox variants</legend>

                        <div class="flex mb-4 mt-7">
                          <div class="flex items-center h-5">
                            <input
                              id="helper-checkbox"
                              onChange={changeCheckbox}
                              aria-describedby="helper-checkbox-text"
                              type="checkbox"
                              value=""
                              class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>
                          <div class="ml-2 text-sm">
                            <label
                              for="helper-checkbox"
                              class="font-medium text-blue-600 dark:text-blue-600"
                            >
                              I have read and agree to the
                            </label>
                            <Link to="/disclaimer">
                              <p
                                id="helper-checkbox-text"
                                class="text-xs font-normal text-blue-600 dark:text-blue-600"
                              >
                                legal disclaimer, terms and conditions
                              </p>
                            </Link>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    {/* ): null} */}
                    {/* {loggedCustomer.data.plan !== "none" ?(
                      <div>
                        <h1 className="text-blue-400">
                          You are currently on a plan!
                        </h1>
                      </div>
                    ): null} */}
                  </div>

                  <hr class="border-gray-700" />

                  <div class="p-6">
                    <h1 class="text-lg font-medium  capitalize lg:text-xl text-white">
                      What’s included:
                    </h1>

                    <div class="mt-8 space-y-4">
                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 text-blue-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <span class="mx-4 text-gray-300">
                          Access to 1 professional portfolio's movements
                        </span>
                      </div>

                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 text-blue-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <span class="mx-4 text-gray-300">
                          Access to a watchlist with 100 stocks and bonds
                        </span>
                      </div>

                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 text-blue-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <span class="mx-4 text-gray-300">
                          Private investor discussion group
                        </span>
                      </div>

                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 text-blue-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <span class="mx-4 text-gray-300">
                          Real-time personal portfolio
                        </span>
                      </div>
                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 text-blue-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <span class="mx-4 text-gray-300">
                          Weekly exclusive Youtube club meetings with JC
                        </span>
                      </div>
                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 text-blue-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <span class="mx-4 text-gray-300">
                          Access to mobile app
                        </span>
                      </div>

                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 text-red-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <span class="mx-4 text-gray-300">
                          Trading portfolio
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="max-w-sm mx-auto border rounded-lg md:mx-4 border-gray-700">
                  <div class="p-6">
                    <h1 class="text-xl font-medium  capitalize lg:text-3xl text-white">
                      Piana Premium
                    </h1>

                    <p class="mt-4 text-gray-300">
                      Get access to our club and our services, including two
                      exclusive portfolios a, 200 stock watchlist and mobile
                      app.
                    </p>

                    <h2 class="mt-4 text-2xl font-medium  sm:text-4xl text-gray-300">
                      $129.99 <span class="text-base font-medium">/month</span>
                    </h2>

                    <p class="mt-1 text-gray-300">
                      Or $899.99 per year{" "}
                      <span className="text-blue-400">
                        (save $659.89 yearly)
                      </span>
                    </p>

                    <button
                      onClick={handleClickPremiumYearly}
                      class="w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                    >
                      Pay Yearly
                    </button>
                    <button
                      onClick={handleClickPremium}
                      class="w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                    >
                      Pay Monthly
                    </button>
                    <fieldset>
                      <legend class="sr-only">Checkbox variants</legend>

                      <div class="flex mb-4 mt-7">
                        <div class="flex items-center h-5">
                          <input
                            id="helper-checkbox"
                            onChange={changeCheckbox}
                            aria-describedby="helper-checkbox-text"
                            type="checkbox"
                            value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </div>
                        <div class="ml-2 text-sm">
                          <label
                            for="helper-checkbox"
                            class="font-medium text-blue-600 dark:text-blue-600"
                          >
                            I have read and agree to the
                          </label>
                          <Link to="/disclaimer">
                            <p
                              id="helper-checkbox-text"
                              class="text-xs font-normal text-blue-600 dark:text-blue-600"
                            >
                              legal disclaimer, terms and conditions
                            </p>
                          </Link>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <hr class="border-gray-700" />

                  <div class="p-6">
                    <h1 class="text-lg font-medium capitalize lg:text-xl text-white">
                      What’s included:
                    </h1>

                    <div class="mt-8 space-y-4">
                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 text-blue-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <span class="mx-4  text-gray-300">
                          Access to 2 professional portfolio's movements
                        </span>
                      </div>

                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 text-blue-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <span class="mx-4 text-gray-300">
                          Access to a watchlist with 200 stocks and bonds
                        </span>
                      </div>

                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 text-blue-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <span class="mx-4 text-gray-300">
                          Private investor discussion group
                        </span>
                      </div>

                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 text-blue-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <span class="mx-4 text-gray-300">
                          Real-time personal portfolio
                        </span>
                      </div>

                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 text-blue-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <span class="mx-4 text-gray-300">
                          Weekly exclusive YouTube club meetings with JC
                        </span>
                      </div>
                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 text-blue-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <span class="mx-4 text-gray-300">
                          Access to mobile app
                        </span>
                      </div>
                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 text-red-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <span class="mx-4 text-gray-300">
                          Trading portfolio
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="max-w-sm mx-auto border rounded-lg md:mx-4 border-gray-700">
                  <div class="p-6">
                    <h1 class="text-xl font-medium  capitalize lg:text-3xl text-white">
                      Piana Pro Trader
                    </h1>

                    <p class="mt-4 text-gray-300">
                      Get access to our club and our services, including two of
                      our portfolios, a trading portfolio, a 300 stock watchlist
                      and our mobile app.
                    </p>

                    <h2 class="mt-4 text-2xl font-medium  sm:text-4xl text-gray-300">
                      $599.99 <span class="text-base font-medium">/month</span>
                    </h2>

                    <p class="mt-1 text-gray-300">
                      Or $4999.99 per year{" "}
                      <span className="text-blue-400">
                        (save $2199.89 yearly)
                      </span>
                    </p>

                    <button
                      onClick={handleClickProTraderYearly}
                      class="w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                    >
                      Pay yearly
                    </button>
                    <button
                      onClick={handleClickProTrader}
                      class="w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                    >
                      Pay monthly
                    </button>
                    <fieldset>
                      <legend class="sr-only">Checkbox variants</legend>

                      <div class="flex mb-4 mt-7">
                        <div class="flex items-center h-5">
                          <input
                            id="helper-checkbox"
                            onChange={changeCheckbox}
                            aria-describedby="helper-checkbox-text"
                            type="checkbox"
                            value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </div>
                        <div class="ml-2 text-sm">
                          <label
                            for="helper-checkbox"
                            class="font-medium text-blue-600 dark:text-blue-600"
                          >
                            I have read and agree to the
                          </label>
                          <Link to="/disclaimer">
                            <p
                              id="helper-checkbox-text"
                              class="text-xs font-normal text-blue-600 dark:text-blue-600"
                            >
                              legal disclaimer, terms and conditions
                            </p>
                          </Link>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <hr class="border-gray-700" />

                  <div class="p-6">
                    <h1 class="text-lg font-medium capitalize lg:text-xl text-white">
                      What’s included:
                    </h1>

                    <div class="mt-8 space-y-4">
                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 text-blue-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <span class="mx-4  text-gray-300">
                          Access to the first 2 portfolios and an exclusive
                          trading one
                        </span>
                      </div>

                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 text-blue-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <span class="mx-4 text-gray-300">
                          Access to a watchlist with 300 stocks and bonds
                        </span>
                      </div>

                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 text-blue-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <span class="mx-4 text-gray-300">
                          Private investor discussion group
                        </span>
                      </div>

                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 text-blue-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <span class="mx-4 text-gray-300">
                          Real time personal portfolio
                        </span>
                      </div>

                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 text-blue-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <span class="mx-4 text-gray-300">
                          Weekly exclusive YouTube club meetings with JC
                        </span>
                      </div>
                      <div class="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5 text-blue-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <span class="mx-4 text-gray-300">
                          Access to mobile app
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-[300px]"></div>
        </div>
      </div>
      <div className=" lg:fixed lg:bottom-0 lg:w-full">
        <Footer />
      </div>
    </div>
  );
};

export default Packages;
