import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home.js";
import Packages from "./pages/Packages.js";
import Login from "./pages/Login.js";
import Register from "./pages/Register.js";
import Payment from "./pages/Payment.js";
import { CustomerContext } from "./CustomerContext.js";
import { useState, useEffect } from "react";
import About from "./pages/About.js";
import Disclaimer from "./pages/Disclaimer.js";
import Success from "./pages/Success.js";
import Recover from "./pages/Recover.js";
import FAQs from "./pages/FAQs.js";
import PasswordSuccess from "./pages/PasswordSuccess.js";
import ReactGA from 'react-ga';
const App = () => {
  const TRACKING_ID = "G-6VKN0FG55P"; 
  ReactGA.initialize(TRACKING_ID);
  const [loggedCustomer, setLoggedCustomer] = useState(null);

  // Save loggedCustomer to localStorage at each rerender

  return (
    <Router>
      <CustomerContext.Provider value={{ loggedCustomer, setLoggedCustomer }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/packages" element={<Packages />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/about" element={<About />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/success" element={<Success />} />
          <Route path="/recover" element={<Recover />} />
          <Route path="/password-success" element={<PasswordSuccess />} />
          <Route path="/faqs" element={<FAQs />} />
        </Routes>
      </CustomerContext.Provider>
    </Router>
  );
};

export default App;
