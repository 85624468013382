import * as React from "react";
import defaultState from "../defaultState.js";

function VariablesForm({ onUpdate }) {
  const [state, setState] = React.useState(defaultState);

  const { initialAmount, period, growthRate, monthlyContribution } = state;

  return (
    <section>
      <div className="grid">
        <label htmlFor="initialAmount">
          Initial Amount ($)
          <input
            type="text"
            id="initialAmount"
            name="initialAmount"
            value={initialAmount}
            onChange={({ target }) =>
              !isNaN(target.value)
                ? setState({ ...state, initialAmount: Number(target.value) })
                : setState({ ...state, initialAmount: 0 })
            }
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 max-w-[80px]"
          />
        </label>
        <label htmlFor="period">
          <div className="w-[200px]">Investment Period (Years)</div>
          <input
            type="text"
            id="period"
            name="period"
            value={period}
            onChange={({ target }) =>
              !isNaN(target.value)
                ? setState({ ...state, period: Number(target.value) })
                : setState({ ...state, period: 0 })
            }
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 max-w-[37px]"
          />
        </label>
        <label htmlFor="growthRate">
          Annual Growth Rate (%)
          <input
            type="text"
            id="growthRate"
            name="growthRate"
            value={growthRate}
            onChange={({ target }) =>
              !isNaN(target.value)
                ? setState({ ...state, growthRate: Number(target.value) })
                : setState({ ...state, growthRate: 0 })
            }
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 max-w-[37px]"
          />
        </label>
        <label htmlFor="monthlyContribution">
          Monthly Contribution ($)
          <input
            type="text"
            id="monthlyContribution"
            name="monthlyContribution"
            value={monthlyContribution}
            onChange={({ target }) =>
              !isNaN(target.value)
                ? setState({
                    ...state,
                    monthlyContribution: Number(target.value),
                  })
                : setState({ ...state, monthlyContribution: 0 })
            }
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 max-w-[80px]"
          />
        </label>
      </div>
      <div className="pt-[20px]"></div>
      <button
        className="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-900 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
        type="button"
        onClick={() => onUpdate(state)}
      >
        Update Chart
      </button>
      <div className="pt-[40px]"></div>
    </section>
  );
}

export default VariablesForm;
