import * as React from "react";
import Summary from "./Summary.js";
import {
  Label,
  LineChart,
  Line,
  XAxis,
  Area,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function renderTooltip({ payload }) {
  if (!payload[0]) {
    return;
  }

  return (
    <span className="text-white">{`$${payload[0].value.toFixed(2)}`}</span>
  );
}

function CompoundInterestChart({
  initialAmount,
  period,
  growthRate,
  monthlyContribution,
  handleUpdate,
}) {
  console.log(initialAmount, period, growthRate, monthlyContribution);
  const data = React.useMemo(() => {
    const result = [];

    for (let i = 1; i <= period; i++) {
      let lastFutureValue = initialAmount + monthlyContribution * 12;
      if (result.length > 0) {
        lastFutureValue =
          result[result.length - 1].value + monthlyContribution * 12;
      }
      result.push({
        label: `${i}`,
        value: lastFutureValue * Math.pow(1 + growthRate / 100, 1),
      });
    }

    return result;
  }, [initialAmount, period, growthRate, monthlyContribution]);

  return (
    <>
      {/* <h2 className="text-white">Projected Growth</h2> */}
      <Summary period={period} data={data} handleUpdate={handleUpdate} />
      <div style={{ width: "100%", height: 300 }}>
        <ResponsiveContainer>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke="#FFFFFF" strokeDasharray="3 3" />
            <XAxis stroke="#FFFFFF" dataKey="label">
              <Label
                fill="#FFFFFF"
                value="Years"
                offset={-3}
                position="insideBottom"
              />
            </XAxis>
            <YAxis stroke="#FFFFFF" />
            <Tooltip content={renderTooltip} />
            <Area
              type="monotone"
              dataKey="temp"
              stroke="#8884d8"
              fill="#8884d8"
            />
            <Line
              stroke="#FFFFFF"
              fill="#FFFFFF"
              type="monotone"
              dataKey="value"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <hr />
    </>
  );
}

export default CompoundInterestChart;
