import Navbar from "../components/Navbar.js";
import background from "../images/background.jpg";
import smartphone from "../images/smartphone.png";
import comingsoon from "../images/comingsoon.png";
import { useState, useEffect } from "react";
import * as React from "react";
import { useContext } from "react";
import CompoundInterestChart from "../components/CompoundInterestChart.js";
import VariablesForm from "../components/VariablesForm.js";
import defaultState from "../defaultState";
import { Link } from "react-router-dom";
import storageService from "../storageService.js";
import { CustomerContext } from "../CustomerContext";
import Footer from "../components/Footer.js";
const Home = () => {
  const [state, setState] = React.useState(defaultState);
  const [page, setPage] = useState(0);
  const { loggedCustomer, setLoggedCustomer } = useContext(CustomerContext);
  const [initialDeposit, setInitialDeposit] = useState(undefined);
  const [contributions, setContributions] = useState(undefined);
  const [timeSpan, setTimeSpan] = useState(undefined);
  const [rateOfReturn, setRateOfReturn] = useState(undefined);

  const [initialAmount, setInitialAmount] = useState(4000);
  const [period, setPeriod] = useState(5);
  const [growthRate, setGrowthRate] = useState(5);
  const [monthlyContribution, setMonthlyContribution] = useState(100);

  useEffect(() => {
    const loggedUserJSON = window.localStorage.getItem("loggedCustomerJWT");
    if (loggedUserJSON) {
      const user = JSON.parse(loggedUserJSON);
      setLoggedCustomer(user);
      storageService.setToken(user.token);
    }
  }, []);

  const updateChart = (event) => {
    event.preventDefault();
    setInitialAmount(initialDeposit);
    setPeriod(timeSpan);
    setGrowthRate(rateOfReturn);
    setMonthlyContribution(contributions);
    console.log(initialAmount);
    console.log(period);
    console.log(growthRate);
    console.log(monthlyContribution);
  };

  const handlePageReturn = () => {
    setPage(0);
    console.log(page);
  };
  const handleUpdate = () => {
    setPage(page + 1);
    console.log(page);
  };
  const handleUpdatePackages = () => {
    setPage(3);
    console.log(page);
  };
  // const handleInitialDeposit = (event) => {
  //   setInitialDeposit(event.target.value);
  // };
  // const handleContributions = (event) => {
  //   setContributions(event.target.value);
  // };
  // const handlePeriod = (event) => {
  //   setPeriod(event.target.value);
  // };
  // const handleTimeSpan = (event) => {
  //   setTimeSpan(event.target.value);
  // };
  // const handleRateOfReturn = (event) => {
  //   setRateOfReturn(event.target.value);
  // };
  const sendForm = () => {};
  return (
    <div className="">
      <Navbar
        handlePageReturn={handlePageReturn}
        handleUpdatePackages={handleUpdatePackages}
      />

      {page === 0 && (
        <div>
          <img
            src={background}
            className="w-full h-[100%] absolute left-0 top-0 z-[-20]"
          />
          <div className="relative text-white left-0 top-0 md:pt-[100px] lg:pl-[40px] pt-[50px] lg:pt-[90px] z-[2] transform translate(0,0) max-w-[90%] lg:max-w-[1250px] m-0 mx-auto animate-fadeInDown">
            <p className="m-0 mt-[18px] mb-[24px] w-[300px] text-0xl lg:w-[912px] max-w-full font-bold">
              INVESTING DOESN'T HAVE TO BE A LONELY SPORT
            </p>
            <h1 className="text-4xl md:text-7xl lg:text-7xl tracking[-1px] w-[912px] lg:leading-[68px] max-w-full mt-[18px] mb-[50px]">
              A private club for investors of all walks of life
            </h1>
            <button onClick={handleUpdate} className="btn btn-two">
              TELL ME MORE
            </button>
          </div>
               {/* <div className="fixed bottom-0 w-full">
        <Footer />
      </div> */}
        </div>
      )}
      {page === 1 && (
        <div>
          <img
            src={background}
            className="min-h-full min-w-[1024px] w-full h-[auto] fixed left-0 top-0 z-[-20]"
          />

          <div class="container px-6 py-4 mx-auto lg:flex lg:justify-end lg:items-end ">
            <div className="w-full relative ">
              <div className="max-w-screen-lg ">
                <div className="w-full lg:w-600 ">
                  <div className="relative text-white left-0 top-0 z-[2] transform translate(0,0) max-w-[90%] lg:max-w-[1250px] m-0 mx-auto animate-fadeInDown">
                    <h1 className="text-4xl md:text-7xl lg:text-7xl tracking[-1px] w-[912px] lg:leading-[68px] max-w-full mt-[18px] mb-[5px]">
                      Did you know how much you can make in the next few years?
                    </h1>
                    <p className="m-0 mt-[0px] mb-[24px] w-[300px] pt-[20px] text-0xl lg:w-[912px] max-w-full font-bold  text-white">
                      TRY A COMPOUND INTEREST INVESTING SIMULATION
                    </p>
                    <div className="w-[50%] h-full relative left-0 z-[-20]">
                      <VariablesForm
                        onUpdate={(variables) => setState(variables)}
                      />
                      
                      {/* <form className="grid grid-cols-1" onSubmit={sendForm}>
                      <label for="initial-deposit text-2xl">
                        INITIAL DEPOSIT
                      </label>
                      <br></br>
                      <div className="flex items-center border-b border-teal-500 py-2 w-full">
                        <input
                          id="initial-deposit"
                          value={initialDeposit}
                          onChange={handleInitialDeposit}
                          className="appearance-none text-2xl lg:text-4xl bg-transparent border-none w-full text-white mr-3 py-1 px-2 leading-tight focus:outline-none"
                          placeholder="$ 1000"
                        />
                      </div>
                      <br></br>
                      <label for="contributions">MONTHLY CONTRIBUTIONS</label>
                      <div className="flex items-center border-b border-teal-500 py-2 w-full">
                        <input
                          id="contributions"
                          value={contributions}
                          onChange={handleContributions}
                          className="appearance-none text-2xl lg:text-4xl relative bg-transparent border-none w-full text-white mr-3 py-1 px-2 leading-tight focus:outline-none"
                          placeholder="$ 100"
                        />
                      </div>

                      <br></br>
                      <label for="period">TIME SPAN (YEARS)</label>
                      <div className="flex items-center border-b border-teal-500 py-2 w-full">
                        <input
                          id="time-span"
                          value={timeSpan}
                          onChange={handleTimeSpan}
                          className="appearance-none text-2xl lg:text-4xl relative bg-transparent border-none w-full text-white mr-3 py-1 px-2 leading-tight focus:outline-none"
                          placeholder="0"
                        />
                      </div>
                      <br></br>
                      <label for="period">RATE OF RETURN</label>
                      <div className="flex items-center border-b border-teal-500 py-2 w-full">
                        <input
                          id="rate-of-return"
                          value={rateOfReturn}
                          onChange={handleRateOfReturn}
                          className="appearance-none text-2xl lg:text-4xl relative bg-transparent border-none w-full text-white mr-3 py-1 px-2 leading-tight focus:outline-none"
                          placeholder="0"
                        />
                      </div>
                      <br></br>
                      <div className="pt-[40px]"></div>
                      <button className="btn btn-two" onClick={updateChart}>
                        SUBMIT
                      </button>
                    </form> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <CompoundInterestChart {...state} handleUpdate={handleUpdate} />
              </div>
            </div>

          </div>
          <div className="mt-[50px] relative bottom-0 w-full ">
        <Footer />
      </div>
        </div>
      )}
      {page === 2 && (
        <div>
          <img
            src={background}
            className="min-h-full min-w-[1024px] w-full h-[auto] fixed left-0 top-0 z-[-20]"
          />

          <div class="container px-6 py-4 mx-auto lg:flex lg:justify-end lg:items-end ">
            <div className="w-full relative ">
              <div className="max-w-screen-lg ">
                <div className="w-full lg:w-600 ">
                  <p className="animate-fadeInUp text-white drop-shadow-md font-fredoka text-stroke-black text-stroke-2 text-6xl lg:text-6xl">
                    JOIN US NOW
                  </p>
                </div>
                <div className="lg:w-450 mt-6 w-900">
                  <p className="animate-fadeInUp text-4xl text-gray-200 drop-shadow-md font-fredoka">
                    Become part of a club of investors from all walks of life.
                  </p>

                  <p className="animate-delay-[800ms] animate-fadeInUp text-1xl text-gray-200 drop-shadow-md font-fredoka mt-6">
                    A private investor social media where investors from
                    different areas can connect and share their strategies.
                  </p>

                  <img className="w-52 pb-3 pt-8" src={comingsoon} />
                  <Link to="/packages">
                    <button className="px-8 py-8 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-900 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                      SEE OUR PACKAGES
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="basis-80">
              <img className="top-44 w-64 pt-8 lg:pt-0" src={smartphone} />
            </div>
          </div>
          <div className="laptop:relative fixed bottom-0 w-full">
        <Footer />
      </div>
        </div>
      )}
    </div>
  );
};

export default Home;
