import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

const renderApp = () => {
  ReactDOM.render(<App />, document.getElementById("root"));
};

// Append a cache-busting query string to static file URLs
const jsUrl =
  process.env.NODE_ENV === "production"
    ? "/static/js/main.js?v=" + Date.now()
    : "/static/js/main.js";
const cssUrl =
  process.env.NODE_ENV === "production"
    ? "/static/css/main.css?v=" + Date.now()
    : "/static/css/main.css";

const link = document.createElement("link");
link.href = cssUrl;
link.rel = "stylesheet";
link.type = "text/css";
document.head.appendChild(link);

const script = document.createElement("script");
script.src = jsUrl;
document.body.appendChild(script);

renderApp();
