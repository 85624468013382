import { Link } from "react-router-dom";
import axios from "axios";
import { useContext } from "react";
import { CustomerContext } from "../CustomerContext";
import storageService from "../storageService";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
const Register = () => {
  const { loggedCustomer, setLoggedCustomer } = useContext(CustomerContext);
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const name = event.target.name.value;
      const email = event.target.email.value;
      const password = event.target.password.value;
      const customer = await axios.post("/api/login", {
        email: email,
        name: name,
        password: password,
      });
      storageService.setToken(customer.data);
      window.localStorage.setItem(
        "loggedCustomerJWT",
        JSON.stringify(customer)
      );

      setLoggedCustomer(customer);
      navigate("/packages");
      console.log("signed up h3llo");
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div>
      <div className="bg-blue-900 font-fredoka">
        <div className="flex justify-center h-screen">
          <div className="hidden bg-cover lg:block lg:w-2/3 bg-journey-login">
            <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-25">
              <div>
                {/* <h2 className="text-4xl font-bold text-white">Brand</h2>
  
                <p className="max-w-xl mt-3 text-gray-300">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. In
                  autem ipsa, nulla laboriosam dolores, repellendus perferendis
                  libero suscipit nam temporibus molestiae
                </p> */}
              </div>
            </div>
          </div>

          <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
            <div className="flex-1">
              <div className="text-center">
                <h2 className="text-4xl font-bold text-center text-white ">
                  Piana Investing Club
                </h2>

                <p className="mt-3 text-white ">
                  Become a part of our exclusive community.
                </p>
              </div>

              <div className="mt-8">
                <form onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="Name"
                      className="block mb-2 text-sm text-gray-300"
                    >
                      Name(s)
                    </label>
                    <input
                      type="name"
                      name="name"
                      id="name"
                      placeholder="Sebastian Vera"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>

                  {/* <div className="mt-6">
                    <label
                      for="username"
                      className="block mb-2 text-sm text-gray-300 "
                    >
                      Choose your username
                    </label>
                    <input
                      type="username"
                      name="username"
                      id="username"
                      placeholder="sebastianvera123"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div> */}

                  <div className="mt-6">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm text-gray-300 "
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="example@example.com"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>

                  <div className="mt-6">
                    <div className="flex justify-between mb-2">
                      <label
                        htmlFor="password"
                        className="text-sm text-gray-300 "
                      >
                        Password
                      </label>
                    </div>

                    <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Your Password"
                      className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>

                  <div className="mt-6">
                    <button className="w-full px-4 py-2 tracking-wide text-black transition-colors duration-200 transform bg-white rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                      Register
                    </button>
                  </div>
                </form>

                <p className="mt-6 text-sm text-center text-gray-400">
                  Already have an account?{" "}
                  <Link
                    to="/login"
                    className="text-white focus:outline-none focus:underline hover:underline"
                  >
                    Log In
                  </Link>
                  .
                </p>
                <p className="mt-2 text-sm text-center text-gray-400">
                  {" "}
                  <Link
                    to="/"
                    className="text-white focus:outline-none focus:underline hover:underline"
                  >
                    Home
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" w-full">
        <Footer />
      </div>
    </div>
  );
};

export default Register;
