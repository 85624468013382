import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { CustomerContext } from "../CustomerContext";
import storageService from "../storageService";
const Navbar = ({ handlePageReturn, handleUpdatePackages }) => {
  const { loggedCustomer, setLoggedCustomer } = useContext(CustomerContext);

  const handleLogout = () => {
    localStorage.clear();
    setLoggedCustomer(null);
    window.location.reload();
  };
  return (
    <nav class="bg-black shadow z-50 font-fredoka animate-fadeInDown">
      <div class="container px-6 py-4 mx-auto lg:flex lg:justify-between lg:items-center  ">
        <div class="lg:flex lg:items-center">
          <div class="flex items-center justify-between">
            <div class="w-56">
              <button onClick={handlePageReturn}>
                <Link to="/">
                  <img src={logo} />
                </Link>
              </button>
            </div>

            {/* Mobile menu button */}
            <div class="flex lg:hidden">
              {loggedCustomer === null ? (
                <Link
                  to="/login"
                  type="button"
                  class="text-indigo-900  hover:text-gray-600  focus:outline-none focus:text-gray-600 "
                  aria-label="toggle menu"
                >
                  LOG IN
                </Link>
              ) : (
                <div class="text-white hidden mt-6 lg:flex lg:mt-0 lg:-mx-2">
                  <button onClick={handleLogout}>LOG OUT</button>
                </div>
              )}
            </div>
          </div>

          <div class="hidden text-indigo-900 capitalize  lg:flex lg:px-16 lg:-mx-4 lg:flex-row lg:items-center">
            <a
              href="#"
              class="text-white mt-2 transition-colors duration-200 transform lg:mt-0 lg:mx-4 hover:text-gray-900 "
            ></a>
          </div>
        </div>

        <div class="text-white hidden mt-6 lg:flex lg:mt-0 lg:-mx-2">
          <Link to="/faqs">FAQs</Link>
        </div>

        <div class="text-white hidden mt-6 lg:flex lg:mt-0 lg:-mx-2">
          <Link to="/packages">SEE OUR PACKAGES</Link>
        </div>

        {loggedCustomer !== null ? (
          <div class="text-white hidden mt-6 lg:flex lg:mt-0 lg:-mx-2">
            <button onClick={handleLogout}>LOG OUT</button>
          </div>
        ) : (
          <div class="text-white hidden mt-6 lg:flex lg:mt-0 lg:-mx-2">
            <Link to="/login">LOG IN</Link>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
