import * as React from "react";

function toNumber(value) {
  return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function Summary({ period, data, handleUpdate }) {
  return (
    <>
      <p className="text-white text-4xl">
        In {period} years, you will have $
        {toNumber(data[data.length - 1].value)}
      </p>
      <h2 className="text-white text-2xl pb-8">Impressed?</h2>
      <button
        onClick={handleUpdate}
        className="px-10 py-7 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-900 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
      >
        JOIN US NOW
      </button>
      <div className="pb-8"></div>
    </>
  );
}

export default Summary;
