import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import { useContext, useState } from "react";

const FAQs = () => {
  const [faq1, setFaq1] = useState(false);
  const [faq2, setFaq2] = useState(false);
  const [faq3, setFaq3] = useState(false);
  const [faq4, setFaq4] = useState(false);
  const [faq5, setFaq5] = useState(false);
  let language = "en";
  return (
    <div className="relative">
      <Navbar />
      <section class="bg-white font-fredoka pb-[200px]">
        <div class="container px-6 py-10 mx-auto">
          <h1 class="text-5xl font-semibold text-[#1c4270] lg:text-3xl ">
            FAQ's
          </h1>

          <hr class="my-6 border-gray-200 " />

          <div>
            <div>
              <button
                onClick={() => setFaq1(!faq1)}
                class="flex items-center focus:outline-none"
              >
                {faq1 ? (
                  <svg
                    class="flex-shrink-0 w-6 h-6 text-[#1C4270]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M20 12H4"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 w-6 h-6 text-[#1C4270]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                )}

                <h1 class="mx-4 text-xl text-gray-700 ">
                  {language === "en"
                    ? "How do I recover my password for Piana Investing Club?"
                    : "Lorem ipsum dolor magni eum quae?"}
                </h1>
              </button>
              {faq1 && (
                <div class="flex mt-8 md:mx-10">
                  <span class="border border-[#1C4270]"></span>

                  <p class="max-w-3xl px-4 text-gray-500">
                    {language === "en"
                      ? " If you have forgotten your password for Piana Investing Club, you can easily recover it on the app's login screen. Just click on the Forgot Password button and follow the prompts to reset your password."
                      : "Consectetur adipisicing elit. Magni, eum quae. Harum officiis reprehenderit ex quia ducimusminima id provident molestias optio nam vel, quidem iurevoluptatem, repellat et ipsa?"}
                  </p>
                </div>
              )}
            </div>
            <hr class="my-8 border-gray-200 " />

            <div>
              <button
                onClick={() => setFaq2(!faq2)}
                class="flex items-center focus:outline-none"
              >
                {faq2 ? (
                  <svg
                    class="flex-shrink-0 w-6 h-6 text-[#1C4270]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M20 12H4"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 w-6 h-6 text-[#1C4270]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                )}

                <h1 class="mx-4 text-xl text-gray-700 ">
                  {language === "en"
                    ? "I'm getting an error message that says 'password and email doesn't match' when I try to log in. What does that mean?            "
                    : "Lorem ipsum dolor imcus locus usic?"}
                </h1>
              </button>
              {faq2 && (
                <div class="flex mt-8 md:mx-10">
                  <span class="border border-[#1C4270]"></span>

                  <p class="max-w-3xl px-4 text-gray-500">
                    {language === "en"
                      ? "This error message means that the email address and password you entered do not match the information in our system. Double-check that you are using the correct email address associated with your Piana Investing Club account and that you are entering the correct password."
                      : "Consectetur adipisicing elit. Magni, eum quae. Harum officiis reprehenderit ex quia ducimusminima id provident molestias optio nam vel, quidem iurevoluptatem, repellat et ipsa?"}
                  </p>
                </div>
              )}
            </div>

            <hr class="my-8 border-gray-200 " />

            <div>
              <button
                onClick={() => setFaq3(!faq3)}
                class="flex items-center focus:outline-none"
              >
                {faq3 ? (
                  <svg
                    class="flex-shrink-0 w-6 h-6 text-[#1C4270]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M20 12H4"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 w-6 h-6 text-[#1C4270]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                )}

                <h1 class="mx-4 text-xl text-gray-700  ">
                  {language === "en"
                    ? "How do I contact Piana Investing Club if I have a question?"
                    : "Kimik aureo ustim?"}
                </h1>
              </button>
              {faq3 && (
                <div class="flex mt-8 md:mx-10">
                  <span class="border border-[#1C4270]"></span>

                  <p class="max-w-3xl px-4 text-gray-500">
                    {language === "en"
                      ? "If you have any questions about Piana Investing Club, you can reach out to us at pianainvestingclub@gmail.com. We are happy to answer any questions you may have about our app, packages, or investing in general."
                      : "Consectetur adipisicing elit. Magni, eum quae. Harum officiis reprehenderit ex quia ducimusminima id provident molestias optio nam vel, quidem iurevoluptatem, repellat et ipsa?"}
                  </p>
                </div>
              )}
            </div>

            <hr class="my-8 border-gray-200 " />

            <div>
              <button
                onClick={() => setFaq4(!faq4)}
                class="flex items-center focus:outline-none"
              >
                {faq4 ? (
                  <svg
                    class="flex-shrink-0 w-6 h-6 text-[#1C4270]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M20 12H4"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 w-6 h-6 text-[#1C4270]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                )}

                <h1 class="mx-4 text-xl text-gray-700 ">
                  {language === "en"
                    ? "How can I purchase a package on Piana Investing Club?            "
                    : "Grisu mizsu intrig pix?"}
                </h1>
              </button>
              {faq4 && (
                <div class="flex mt-8 md:mx-10">
                  <span class="border border-[#1C4270]"></span>

                  <p class="max-w-3xl px-4 text-gray-500">
                    {language === "en"
                      ? "To purchase a package on Piana Investing Club, simply visit our packages section while logged in on www.pianainvestingclub.com/packages and select the package you would like to purchase. Follow the prompts to complete the transaction, and you will receive access to your chosen package."
                      : "Consectetur adipisicing elit. Magni, eum quae. Harum officiis reprehenderit ex quia ducimusminima id provident molestias optio nam vel, quidem iurevoluptatem, repellat et ipsa?"}
                  </p>
                </div>
              )}
            </div>

            <hr class="my-8 border-gray-200 " />

            <div>
              <button
                onClick={() => setFaq5(!faq5)}
                class="flex items-center focus:outline-none"
              >
                {faq5 ? (
                  <svg
                    class="flex-shrink-0 w-6 h-6 text-[#1C4270]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M20 12H4"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 w-6 h-6 text-[#1C4270]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                )}

                <h1 class="mx-4 text-xl text-gray-700 ">
                  {language === "en"
                    ? "What types of investing packages are available on Piana Investing Club?"
                    : "Harum officiis quia?"}
                </h1>
              </button>
              {faq5 && (
                <div class="flex mt-8 md:mx-10">
                  <span class="border border-[#1C4270]"></span>

                  <p class="max-w-3xl px-4 text-gray-500">
                    {language === "en"
                      ? "Currently, Piana Investing Club offers three types of packages: Starter, Premium and Pro Trader. The Starter package offers a choice of two portfolios: Portfolio 1 and Portfolio 2. Portfolio 1 is designed for investors who are looking for a balance between risk and safety. Portfolio 2 is more Europe-oriented and is designed to provide a safer investment option. The Premium package offers access to both Portfolio 1 and Portfolio 2. These portfolios are tailored to specific investment goals and risk tolerances. All of our packages come with educational resources and support from our community to help you achieve your investment goals. Visit our website to learn more about our packages and investment options."
                      : "Consectetur adipisicing elit. Magni, eum quae. Harum officiis reprehenderit ex quia ducimusminima id provident molestias optio nam vel, quidem iurevoluptatem, repellat et ipsa?"}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="fixed bottom-0 w-full">
        <Footer />
      </div>
    </div>
  );
};

export default FAQs;
